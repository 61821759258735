html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

#bodyWrapper {
    flex: 1 0 auto;
}

#footer {
    flex-shrink: 0;
}

body, html, h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0
}

h1, h2, h3, h4 {
    padding-bottom: 5px;
}

body {
    font: 14px Verdana, Arial, sans-serif;
    background-color: #f3f3f3;
    color: black;
    position: relative;
}

#content {
    padding: 20px;
}

.wrapper {
    margin: 0 auto;
}

#header .wrapper {
    text-align: center;
    padding: 20px 0;
}

#body {
    width: 460px;
    margin: 30px auto;
}

#footer {
    margin-bottom: 15px;
    font-size: 10px;
}

#header {
    height: auto !important;
}

#socialLoginList {
    display: block;
    text-align: center;
    margin-top: 20px;
    align-content: center;
}

.outsideSite-button {
    height: 45px;
    background-color: #f0f4f1;
    border: solid 1px #bcbcba;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #433d3c;
    margin-top: 17px;
}

.outsideSite-button:hover {
    background-color: #dce3dd;
}

.Clever-button {
    width: 207px;
    background: url(../../images/clever-icon.png) no-repeat 7% 50% #f0f4f1;
}

.ClassLink-button {
    width: 230px;
    background: url(../../images/classlink-icon.png) no-repeat 2.5% 50%/30px #f0f4f1;
}

.logo-separator {
    width: 1px;
    height: 95%;
    background-color: #f0f4f1;
    border: solid 1px #bcbcba;
    margin: 0 20px;
}

.external-logins-separator-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 10px;
}

.external-logins-separator-container .external-logins-separator {
    -webkit-box-flex: 1 0 auto;
    -webkit-flex: 1 0 auto;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    -o-flex: 1 0 auto;
    flex: 1 0 auto;
    min-width: 1px;
    border-bottom: 1px solid lightgrey;
}

.external-logins-separator-container .external-logins-separator-text {
    padding: 0 10px;
    font-size: 12px;
    font-weight: 700;
    color: #737373;
}

.validation-summary-errors ul {
    list-style: none;
    padding-left: 0px;
}

.not-yet-a-customer-container {
    text-align: center;
    margin-top: 50px;
}

.not-yet-a-customer-link {
    background-color: #f3f3f3;
    border: 1px solid #D3D3D3;
    color: #0c4780;
}
