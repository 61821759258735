﻿//
// Boostratp customizations
//

// The idea for how to override bootstrap css came from http://stackoverflow.com/a/33172105

@import "../node_modules/bootstrap/less/bootstrap.less";

// Load Bootsrap fonts from this directory.
@icon-font-path:          "fonts/";

@media print {
    *.color-print,
    *.color-print:before,
    *.color-print:after {
        color: unset !important;
    }
}

/* When FireFox client-side validation detects an invalid input, it denotes it using the box-shadow style.  Beginning 
   with version 53, this styling does not override existing box-shadow styling. The style below is copied from Mozilla 
   source code. */
/* https://hg.mozilla.org/mozilla-central/file/tip/layout/style/res/forms.css#l888 */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/:invalid */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-ui-invalid */
.form-control:not(output):-moz-ui-invalid {
    box-shadow: 0 0 1.5px 1px red;
}
.form-control:not(output):-moz-ui-invalid:-moz-focusring {
    box-shadow: 0 0 2px 2px rgba(255,0,0,0.4);
}
