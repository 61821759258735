/* Atomic CSS */
/* Naming conventions coming from http://docs.emmet.io/cheat-sheet/ */

/* FONT SIZE */
.fz-sr { font-size: 65%; }
.fz-s { font-size: 75%; }
.fz-m { font-size: 100%; }
.fz-l { font-size: 120%; }
.fz-xl { font-size: 150%; }
.fz-xxl { font-size: 175%; }

/* FONT WEIGHT */
.fw { font-weight: normal }
.fw-lr {font-weight: lighter;}
.fw-b { font-weight:bold; }

/* FONT STYLE */
.fs-i { font-style: italic; }

/* FONT COLOR */
.c-b { color: black; }
.c-w { color:white; }
.c-t { color:transparent; }

/* FLOAT */
.fl-start, .fl { float:left; }
.fl-end { float:right; }

/* TEXT ALIGN */
.ta-r { text-align:right; }
.ta-c { text-align:center; }
.ta-l { text-align:left; }

/* TEXT DECORATION */
.td { text-decoration: none }
.td-u { text-decoration: underline }

/* MARGIN */
.m-n { margin: 0; }
.m-s { margin:5px; }
.m-m, .m { margin:10px; }
.m-l { margin:20px; }
.mb-n { margin-bottom: 0; }
.mb-s { margin-bottom:5px; }
.mb-m, .mb { margin-bottom:10px; }
.mb-l { margin-bottom:20px; }
.mb-xl { margin-bottom:40px; }
.mr-n { margin-right: 0; }
.mr-s { margin-right: 5px; }
.mr-m, .mr { margin-right: 10px; }
.mr-l { margin-right: 20px; }
.ml-n { margin-left: 0; }
.ml-s { margin-left: 5px; }
.ml-m, .ml { margin-left: 10px; }
.ml-l { margin-left: 20px; }
.ml-xl { margin-left: 40px; }
.mt-n { margin-top: 0; }
.mt-s { margin-top: 5px; }
.mt-m, .mt { margin-top: 10px; }
.mt-l { margin-top: 20px; }
.mt-xl { margin-top: 40px; }

/* PADDING */
.p-n { padding: 0px; }
.p-s { padding: 5px; }
.p-m, .p { padding: 10px; }
.p-l { padding: 20px; }
.pb-n { padding-bottom: 0px; }
.pb-s { padding-bottom: 5px; }
.pb-m { padding-bottom: 10px; }
.pb-l { padding-bottom: 20px; }
.pr-n { padding-right: 0px; }
.pr-s { padding-right: 5px; }
.pr-m { padding-right: 10px; }
.pr-l { padding-right: 20px; }
.pl-n { padding-left: 0px; }
.pl-s { padding-left: 5px; }
.pl-m{ padding-left: 10px; }
.pl-l{ padding-left: 20px; }
.pt-n { padding-top: 0px; }
.pt-s { padding-top: 5px; }
.pt-m { padding-top: 10px; }
.pt-l { padding-top: 20px; }

/* BORDER RADIUS */
.bdrs-s { border-radius:3px; }
.bdrs-m, .bdrs { border-radius:5px; }
.bdrs-l { border-radius:10px; }
.bdtlrs-s { border-top-left-radius: 3px; }
.bdtlrs-m { border-top-left-radius: 3px; }
.bdblrs-s { border-bottom-left-radius: 3px; }
.bdblrs-m { border-bottom-left-radius: 5px; }
.bdtrrs-s { border-top-right-radius: 3px; }
.bdtrrs-m { border-top-right-radius: 3px; }
.bdbrrs-s { border-bottom-right-radius: 3px; }
.bdbrrs-m { border-bottom-right-radius: 5px; }

/* BORDER */
.bd, .bd-s { border: thin #ddd solid; }
.bdt, .bdt-s { border-top: thin #ddd solid; }
.bdb, .bdb-s { border-bottom: thin #ddd solid; }
.bdr, .bdr-s { border-right: thin #ddd solid; }
.bdl, .bdl-s { border-left: thin #ddd solid; }

/* BACKGROUND */
.bg-t { background-color: transparent; }
.bg-lg { background:#f2f0eb; }
.bg-w { background:#fff; }
.bg-dg { background:#726f6e; }

/* WIDTH */
.w-a { width: auto; }
.w-5 { width: 5em }
.w-10 { width:10em; }
.w-15 { width:15em; }
.w-20 { width:20em; }
.w-25 { width:25em; }
.w-30 { width:30em; }
.w-35 { width:35em; }
.w-40 { width:40em; }
.w-45 { width:45em; }
.w-50 { width:50em; }
.w-55 { width:55em; }
.w-60 { width:60em; }
.w-65 { width:65em; }
.w-70 { width:70em; }
.w-75 { width:75em; }
.w-80 { width:80em; }
.w-90 { width:90em; }
.w-100 { width:100em; }

.w-5p { width: 5% }
.w-10p { width:10%; }
.w-15p { width:15%; }
.w-20p { width:20%; }
.w-25p { width:25%; }
.w-30p { width:30%; }
.w-35p { width:35%; }
.w-40p { width:40%; }
.w-45p { width:45%; }
.w-50p { width:50%; }
.w-55p { width:55%; }
.w-60p { width:60%; }
.w-65p { width:65%; }
.w-70p { width:70%; }
.w-75p { width:75%; }
.w-80p { width:80%; }
.w-90p { width:90%; }
.w-100p { width:100%; }

/* HEIGHT*/
.h-5 { height: 5em; }
.h-7 { height: 7em; }
.h-10 { height: 10em; }
.h-20 { height: 20em; }
.h-30 { height: 30em; }
.h-auto { height: auto; }

/* DISPLAY */
.d-n { display: none }
.d-b, .d  { display:block; }
.d-i { display:inline; }
.d-ib { display:inline-block; }
.d-fx { display: flex }
.d-ifx { display: flex }

/* LISTS */
.lis, .lis-d { list-style:disc; }
.lis-n { list-style:none; }
.lis-a { list-style:georgian; }

/* OVERFLOW */
.ov, .ov-h { overflow: hidden }
.ov-v { overflow: visible }
.ov-a { overflow: auto }
.ov-s { overflow: scroll }

/* Cursor */
.cursor-p { cursor: pointer; }

/* Vertical alignment */
.va-m { vertical-align: middle; }

/* Table layout */
.tl-f { table-layout: fixed; }

/* Word-Break*/
.wb-bw { word-break: break-all; }

/* Flexible box layout (flexbox) */
.fxd-r { flex-direction: row; }
.fxd-rr { flex-direction: row-reverse; }
.fxd-c { flex-direction: column; }
.fxd-cr { flex-direction: column-reverse; }

.ai-fs { align-items: flex-start }
.ai-fe { align-items: flex-end }
.ai-c { align-items: center }
.ai-bs { align-items: baseline }
.ai-s { align-items: stretch }

.fxw-n { flex-wrap: nowrap; }
.fxw-w { flex-wrap: wrap; }
.fxw-wr { flex-wrap: wrap-reverse; }

.fxg0 { flex-grow: 0; }
.fxg1 { flex-grow: 1; }
.fxg2 { flex-grow: 2; }
.fxg3 { flex-grow: 3; }
.fxg4 { flex-grow: 4; }
.fxg5 { flex-grow: 5; }
.fxg6 { flex-grow: 6; }
.fxg7 { flex-grow: 7; }
.fxg8 { flex-grow: 8; }
.fxg9 { flex-grow: 9; }
.fxg10 { flex-grow: 10; }

.fxs0 { flex-shrink: 0; }
.fxs1 { flex-shrink: 1; }
.fxs2 { flex-shrink: 2; }
.fxs3 { flex-shrink: 3; }
.fxs4 { flex-shrink: 4; }
.fxs5 { flex-shrink: 5; }
.fxs6 { flex-shrink: 6; }
.fxs7 { flex-shrink: 7; }
.fxs8 { flex-shrink: 8; }
.fxs9 { flex-shrink: 9; }
.fxs10 { flex-shrink: 10; }

.fxjc-fs { justify-content: flex-start; }
.fxjc-fe { justify-content: flex-end; }
.fxjc-c { justify-content: center; }
.fxjc-sb { justify-content: space-between; }
.fxjc-sa { justify-content: space-around; }
.fxjc-se { justify-content: space-evenly; }

.fxai-fs { align-items: flex-start; }
.fxai-fe { align-items: flex-end; }
.fxai-c { align-items: center; }
.fxai-s { align-items: stretch; }
.fxai-b { align-items: baseline; }